import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';

const COMING_SOON = process.env.REACT_APP_COMING_SOON === 'true';

async function render() {
  const { default: Component } = COMING_SOON ? await import('./pages/ComingSoon') : await import('./Providers');

  ReactDOM.render(
    <React.StrictMode>
      <Component /> 
    </React.StrictMode>,
    document.getElementById('root')
  );
}

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
